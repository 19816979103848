<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <div v-if="subscriptionCheck('active')">
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-account-check-outline
        </v-icon> Check Ins
      </h2>
      <v-card
        shaped
        color="#CFD8DC"
      >
        <br>
        <br>
        <br>
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              lg="4"
              md="5"
              sm="12"
            >
              <v-text-field
                v-model="search"
                :hint="isSearchAdded ? searchHint : ''"
                dense
                persistent-hint
                label="Search"
                append-icon="mdi-magnify"
                outlined
                @keyup.enter="getRecordsFromServer({itemsPerPage: 10, pageStart: 0})"
                @keydown.esc="getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="contractCheckInsList"
          :search="search"
          disable-sort
          :footer-props="{
            'items-per-page-options': [10, 20, 30]
          }"
          :loading="updateProgress"
          :server-items-length="totalRecords"
          class="elevation-1"
          mobile-breakpoint="100"
          light
          @pagination="getRecordsFromServer"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.app_user.first_name }} {{ item.app_user.last_name }}
                <v-tooltip
                  v-if="dailyWorkHrLimitReached(item)"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      color="#C62828"
                      v-on="on"
                    >
                      mdi-alert
                    </v-icon>
                  </template>
                  <span>Exceeded Daily Work Hour Limit</span>
                </v-tooltip>
              </td>
              <td>{{ item.app_user.company.name }}</td>
              <td>
                <font v-if="item.app_user.worker_category !== null">
                  {{ item.app_user.worker_category.name }}
                </font>
                <font v-else>
                  N/A
                </font>
              </td>
              <td class="my-type-style">
                <v-chip
                  outlined
                  class="type"
                  color="#37474F"
                  dark
                >
                  {{ item.type }}
                </v-chip>
              </td>
              <td>
                <font v-if="item.budget !== null">
                  {{ item.budget.name }}
                </font>
                <font v-else>
                  N/A
                </font>
              </td>
              <td>{{ item.checkin_time | convertToLocal }}</td>
              <td>{{ item.checkin_gate.name }}</td>
              <td>
                <font v-if="item.checkout_time !== null">
                  {{ item.checkout_time | convertToLocal }}
                  <v-tooltip
                    v-if="item.manual_checkout === true"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        color="#C62828"
                        v-on="on"
                      >
                        mdi-account-arrow-right
                      </v-icon>
                    </template>
                    <span>Checkout Was Done Manually</span>
                  </v-tooltip>
                </font>
                <font
                  v-else
                  class="My-checkout-time"
                >
                  Active*
                </font>
              </td>
            </tr>
          </template>
        </v-data-table>
        <br>
      </v-card>
      <centre-spinner
        :loading="loading"
      />
    </div>
    <div v-else>
      <v-alert
        text
        prominent
        type="warning"
        color="#006883"
        border="left"
        class="alert-style"
      >
        Upgrade to Premium Version to view the CheckIns.
      </v-alert>
    </div>
  </v-container>
</template>

<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import moment from 'moment';
import global from 'src/mixins/global';

  export default {
    name: 'ContractCheckIns',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    mixins: [global],
    data () {
      return {
        search: null,
        loading: false,
        headers: [
          { text: 'Name', align: 'start', value: 'app_user.first_name' },
          { text: 'Company', value: 'company.name' },
          { text: 'Category', value: 'worker_category.name' },
          { text: 'Check-In Type', value: 'checkin_type' },
          { text: 'Check-In Item', value: 'budget.name' },
          { text: 'Check-In At', value: 'checkin_time' },
          { text: 'Check-In Gate', value: 'checkin_gate.name' },
          { text: 'Check-Out At', value: 'checkout_time' },
        ],
        updateProgress: false,
      };
    },
    computed: {
      paginatedRecordsStructure () {
        return this.$store.getters['projects/getContractCheckIns'];
      },
      contractCheckInsList () {
        const records = this.paginatedRecordsStructure;
        return records.data;
      },
      totalRecords () {
        if (Object.keys(this.paginatedRecordsStructure).length > 0) {
          return this.paginatedRecordsStructure.total;
        }
        return 0;
      },
      isSearchAdded () {
        if (this.search === null || this.search === '') {
          return false;
        }
        return true;
      },
      searchHint () {
        return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
      },
    },
    watch: {
      isSearchAdded (val) {
        if (val === false) {
          this.getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
        }
      },
    },
    async mounted () {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    methods: {
      async getAllRecordsFromServer ({ itemsPerPage, pageStart }) {
        this.updateProgress = true;
        this.search = null;
        await this.$store.dispatch('projects/fetchContractCheckIns', {
           projectContractId: this.$route.params.contractId,
          params: {
            params: {
              limit: itemsPerPage,
              startPage: pageStart,
            },
          },
        }).catch(() => {
          this.updateProgress = false;
        });
        this.updateProgress = false;
      },
      async getRecordsFromServer ({ itemsPerPage, pageStart }) {
        this.updateProgress = true;
        await this.$store.dispatch('projects/fetchContractCheckIns', {
          projectContractId: this.$route.params.contractId,
          params: {
            params: {
              name: this.search === '' ? null : this.search,
              limit: itemsPerPage,
              startPage: pageStart,
            },
          },
        }).catch(() => {
          this.updateProgress = false;
        });
        this.updateProgress = false;
      },
      dailyWorkHrLimitReached (checkInDetails) {
        const checkInTime = new Date(checkInDetails.checkin_time).getTime();
        if (checkInDetails.checkout_time === null) {
          const currentTime = new Date().getTime();
          var diff = currentTime - checkInTime;
          var hrDiff = Math.floor(diff / 3.6e6);
          if (checkInDetails.app_user.work_hours <= hrDiff) {
            return true;
          }
          return false;
        } else {
          const checkOutTime = new Date(checkInDetails.checkout_time).getTime();
          var totalWorkHrs = checkOutTime - checkInTime;
          var hours = Math.floor(totalWorkHrs / 3.6e6);
          if (checkInDetails.app_user.work_hours <= hours) {
            return true;
          }
          return false;
        }
      },
    },
  };
</script>

<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC;
}
.v-data-table {
  background-color: #ECEFF1
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.my-type-style {
  text-transform: capitalize;
}
.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
